import { createRouter, createWebHistory } from 'vue-router'
import NotFoundPage from '../views/404.vue';

let routes = [
  {
    path: '/admin/:catchAll(.*)',
    name: "admin_editor_not_found",
    component: () => import("@/views/admin.vue"),
  },
  {
    path: '/:catchAll(.*)', // 使用 :catchAll(.*) 来匹配任何路径
    name: "notfoundpage",
    component: NotFoundPage
  },
  { path: "/", component: () => import("../views/index.vue"), name: "/" },
  { path: "/post", component: () => import("../views/all_post.vue"), name: "post" },
  {
    path: "/info/:keyid", component: () => import("../views/info.vue"),
    // 可以添加一个名称，便于在代码中通过名称引用路由
    name: 'infopage'
  },
  {
    path: "/login", component: () => import("../views/login.vue"), name: "login"
  },
  {
    path: "/admin", component: () => import("../views/admin.vue"),
    name: "admin",
    children: [
      { path: "/admin/article", component: () => import("../views/back/article.vue") },
      { path: "/admin/editor/:keyid", name: "admin_editor", component: () => import("../views/back/editor.vue") },
      { path: "/admin/info/:keyid", name: "admin_info", component: () => import("@/views/back/info.vue") }

      //{ path: "users", component: () => import("../views/users.vue") },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 检查是否是包含 '/admin' 的路由且 token 不存在
  if (to.path.includes('/admin') && !localStorage.getItem("satoken")) {
    next({ name: 'login' }); // 使用路由名称进行重定向
  } else if (to.path.includes('/info/') && (!to.params.keyid || to.params.keyid === '')) {
    // 检查是否是 '/info' 路由且 keyid 参数不存在
    next({ name: 'notfoundpage' }); // 使用路由名称进行重定向
  } else {
    next(); // 继续到目标路由
  }
});
export { router, routes }

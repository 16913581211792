<template>
  <div class="not-found-page">
    <div class="not-found-container">
      <h1 class="error-code">404</h1>
      <h2 class="error-message">你找的页面丢失了</h2>
      <p class="error-description">哎呀，看起来你访问的页面不存在或已被删除。</p>
      <router-link to="/" class="home-link">返回首页</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage'
};
</script>

<style scoped>
.not-found-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  font-family: 'Courier New', Courier, monospace;
}

.not-found-container {
  text-align: center;
}

.error-code {
  font-size: 4em;
  color: #333;
}

.error-message {
  font-size: 2em;
  color: #555;
  font-weight: bold;
}

.error-description {
  font-size: 1.2em;
  color: #777;
  margin-bottom: 1.5em;
}

.home-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.home-link:hover {
  background-color: #0056b3;
}
</style>